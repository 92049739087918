@import "./image-gallery.css";

html,
body,
#root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
